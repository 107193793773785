import { useContext } from 'react'
import ConfirmContext from './context'
import { SHOW_CONFIRM } from './reducer'

const useConfirmModal = () => {
  const [_, dispatch] = useContext(ConfirmContext)

  return async (props) => {
    const { title, subtitle, confirmLabel, cancelLabel, children, confirmColor } = typeof props === 'object' ? props : { title: props }
    return new Promise(resolve => {
      dispatch({
        type: SHOW_CONFIRM,
        payload: {
          promise: { resolve },
          title,
          subtitle,
          children,
          confirmLabel,
          cancelLabel,
          confirmColor,
        }
      })
    })
  }
}

export default useConfirmModal
