export const SHOW_CONFIRM = 'SHOW_CONFIRM'
export const HIDE_CONFIRM = 'HIDE_CONFIRM'

export const initialState = {
  promise: null,
  isOpen: false,
  title: undefined,
  subtitle: undefined,
  children: undefined,
  confirmLabel: undefined,
  cancelLabel: undefined,
  isSubmitting: false,
  confirmColor: undefined,
}

export const reducer = (state, { type, payload }) => {
  const actions = {
    [SHOW_CONFIRM]: () => ({
      ...initialState,
      ...payload,
      isOpen: true,
    }),
    [HIDE_CONFIRM]: () => ({
      ...state,
      isOpen: false
    })
  }

  const action = actions[type]
  return typeof action === 'function' ? action() : state
}