import React, { ForwardedRef, forwardRef, PropsWithChildren } from 'react'
import { Slot, Slottable } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { cn } from '@design-system'

const switchCardVariants = cva(
  'w-full flex justify-between items-center gap-2 p-3 rounded-md transition-colors text-left',
  {
    variants: {
      defaultValue: {
        true: 'bg-green-5 dark:bg-green-70',
        false: 'bg-gray-10 text-gray-50 dark:bg-gray-95 dark:bg-opacity-90',
      },
    },
    defaultVariants: {
      defaultValue: false,
    },
  }
)
export interface SwitchCardProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'defaultValue'>, VariantProps<typeof switchCardVariants> {
  /**
   * Use the asChild prop to compose [Radix's](https://www.radix-ui.com/primitives/docs/guides/composition) functionality onto alternative element types or your own React components.
   */
  asChild?: boolean
  /**
   * Avoid using classes that affect the typographic style.
   */
  className?: string
  viewOnly?: boolean
}

export const SwitchCard = forwardRef<HTMLButtonElement, SwitchCardProps>
  (({ className, defaultValue, viewOnly, children, asChild = false, ...props }: SwitchCardProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(switchCardVariants({ className, defaultValue }))}
        ref={ref}
        {...props}
      >
        <Slottable>
          {children}
        </Slottable>
        {!viewOnly && <SwitchButton defaultValue={defaultValue} disabled={props.disabled} />}
      </Comp>
    )
  })

SwitchCard.displayName = "SwitchCard"

const switchButtonVariants = cva(
  'w-11 h-6 rounded-full flex items-center shrink-0 border-2 transition-colors',
  {
    variants: {
      defaultValue: {
        true: 'bg-green-30 border-green-30 dark:bg-green-40 dark:border-green-40',
        false: 'bg-gray-40 border-gray-40 dark:bg-gray-100 dark:border-gray-100',
      },
      disabled: {
        true: 'opacity-50',
      }
    },
    defaultVariants: {
      defaultValue: false,
      disabled: false
    },
  }
)

interface SwitchButtonProps extends VariantProps<typeof switchButtonVariants> { }

const SwitchButton = ({ defaultValue, disabled }: SwitchButtonProps) => {
  return (
    <div role="switch" className={cn(switchButtonVariants({ defaultValue, disabled }))}>
      <span data-testid="switch-internal" className={cn("w-5 h-5 rounded-full shrink-0 bg-white transition-transform", defaultValue ? "translate-x-5" : "translate-x-0")}></span>
    </div>
  )
}
