import React, { ForwardedRef, forwardRef } from 'react'
import { Slot, Slottable } from '@radix-ui/react-slot'
import { cva, type VariantProps } from 'class-variance-authority'
import { Icon, Spinner, cn } from '@design-system'

const buttonVariants = cva(
  '!ring-0 relative cursor-pointer inline-flex items-center justify-center whitespace-nowrap rounded-full font-bold font-sans transition-colors focus-within:outline-none focus-within:ring-2 focus-within:ring-inset disabled:pointer-events-none',
  {
    variants: {
      /**
       * The variant of the button
       */
      variant: {
        contained: 'border-2 border-transparent disabled:bg-gray-50 dark:disabled:bg-gray-70',
        outlined: 'border-2 disabled:text-gray-60 dark:disabled:text-gray-70 disabled:border-gray-60 dark:disabled:border-gray-70',
        ghost: 'disabled:text-gray-60 dark:disabled:text-gray-70',
        blur: 'dark backdrop-blur-xl bg-alpha/10 text-white hover:bg-alpha/20 active:bg-alpha/30 focus-within:bg-alpha/10 focus-within:ring-accent disabled:bg-alpha/10 disabled:text-alpha/50',
        input: 'border-2 rounded-lg font-normal bg-white dark:bg-gray-90 text-gray-100 dark:text-white border-gray-30 dark:border-gray-60 hover:bg-gray-10 dark:hover:bg-gray-95 focus-within:bg-white-10 dark:focus:bg-gray-100 focus-within:ring-0 disabled:text-gray-60 dark:disabled:text-gray-70 disabled:border-gray-60 dark:disabled:border-gray-70',
      },
      color: {
        dark: '',
        light: '',
        soft: '',
        accent: '',
        danger: '',
      },
      /**
       * The size of the button
       */
      size: {
        xs: 'h-8 px-3 text-xs gap-1',
        sm: 'h-9 px-4 text-sm gap-1',
        md: 'h-11 px-5 text-base gap-2',
        lg: 'h-12 px-6 text-lg gap-2',
      }
    },
    compoundVariants: [
      {
        variant: 'input',
        size: 'lg',
        className: 'px-4 text-base',
      },
      {
        variant: 'contained',
        color: 'dark',
        className: 'bg-gray-100 dark:bg-white text-white dark:text-gray-100 hover:bg-alpha/85 dark:hover:bg-alpha/80 active:bg-alpha-80 focus-within:bg-alpha-90 focus-within:ring-accent',
      },
      {
        variant: 'contained',
        color: 'light',
        className: 'bg-white text-gray-100 hover:bg-white/85 dark:hover:bg-white/80 active:bg-white-80 focus-within:bg-white-90 focus-within:ring-blue-20',
      },
      {
        variant: 'contained',
        color: 'soft',
        className: 'bg-gray-100/10 dark:bg-white/80 text-gray-100 hover:bg-gray-100/20 dark:hover:bg-white/60 active:bg-gray-100/50 dark:active:bg-white/50 focus-within:bg-gray-100/20 dark:focus-within:bg-white/60',
      },
      {
        variant: 'contained',
        color: 'accent',
        className: 'bg-accent text-white dark:text-gray-100 hover:bg-accent/90 active:bg-accent/80 focus-within:bg-accent/90 focus-within:ring-accent',
      },
      {
        variant: 'contained',
        color: 'danger',
        className: 'bg-danger text-white dark:text-gray-100 hover:bg-danger/90 active:bg-danger/80 focus-within:bg-danger/90',
      },
      {
        variant: 'outlined',
        color: 'dark',
        className: 'text-gray-100 dark:text-white border-gray-100 dark:border-white hover:bg-alpha/5 focus-within:bg-alpha-5 focus-within:ring-accent',
      },
      {
        variant: 'outlined',
        color: 'light',
        className: 'text-white border-white hover:bg-white/10 focus-within:bg-white-10 focus-within:ring-blue-20',
      },
      {
        variant: 'outlined',
        color: 'soft',
        className: 'text-gray-100 dark:text-white border-gray-30 dark:border-gray-60 hover:bg-alpha/5 active:bg-alpha/10 focus-within:bg-alpha/5 focus-within:ring-accent',
      },
      {
        variant: 'outlined',
        color: 'accent',
        className: 'text-accent border-accent hover:bg-accent/5 active:bg-accent/5 focus-within:bg-accent/5',
      },
      {
        variant: 'outlined',
        color: 'danger',
        className: 'text-danger border-danger hover:bg-danger/5 active:bg-danger/5 focus-within:bg-danger/5',
      },
      {
        variant: 'ghost',
        color: ['dark', 'soft'],
        className: 'text-gray-100 dark:text-white hover:bg-alpha/5 active:bg-alpha/10 focus-within:bg-alpha/5 focus-within:ring-accent',
      },
      {
        variant: 'ghost',
        color: 'light',
        className: 'text-white hover:bg-white/10 active:bg-white/15 focus-within:bg-white/10 focus-within:ring-blue-20',
      },
      {
        variant: 'ghost',
        color: 'accent',
        className: 'text-accent hover:bg-accent/5 active:bg-alpha/10 focus-within:bg-accent/5',
      },
      {
        variant: 'ghost',
        color: 'danger',
        className: 'text-danger hover:bg-danger/5 active:bg-alpha/10 focus-within:bg-danger/5',
      },
    ],
    defaultVariants: {
      variant: 'contained',
      color: 'dark',
      size: 'md'
    },
  }
)

//export type ButtonProps = React.HTMLAttributes<HTMLSpanElement> & VariantProps<typeof buttonVariants> & {
export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  /**
   * Use the asChild prop to compose [Radix's](https://www.radix-ui.com/primitives/docs/guides/composition) functionality onto alternative element types or your own React components.
   */
  asChild?: boolean
  /**
   * If true, the button will be disabled and show a spinner
   */
  loading?: boolean
  /**
   * The color of the button
   */
  color?: VariantProps<typeof buttonVariants>['color']
}

/**
Buttons express what action will occur when the users clicks. Buttons are used to initialize an action, either in the background or foreground of an experience.

## General guidance

Buttons are used primarily on action items. Some examples include Add, Save, Delete, and Sign up. Each page can have one or two primary buttons. Any remaining calls-to-action should be represented as secondary buttons.

## Labels
Button labels should clearly indicate the action of the button. Use active verbs, such as Add or Delete. Use no more than three words for button labels.

For sets of buttons, use specific labels, such as Save or Discard, instead of using OK and Cancel. This is particularly helpful when the user is confirming an action.
*/
export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, color, size, loading, asChild = false, children, ...props }: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const Comp = asChild ? Slot : 'button'
    const isOnlyIcon = checkIfIsOnlyIcon(children, asChild)
    return (
      <Comp
        className={cn(buttonVariants({ variant, color, size, className }), isOnlyIcon && 'aspect-square !px-0', loading && 'transition-none pointer-events-none text-transparent dark:text-transparent')}
        ref={ref}
        {...props}
      >
        {loading && (<span className='absolute inset-0 flex justify-center items-center'><Spinner size={size} color={['outlined', 'ghost'].includes(variant) ? 'dark' : 'light'} /></span>)}
        <Slottable>
          {children}
        </Slottable>
      </Comp>
    )
  }
)

Button.displayName = 'Button'

const checkIfIsOnlyIcon = (children: React.ReactNode, asChild: boolean): boolean => {
  if (asChild) {
    const child = React.Children.only(children) as React.ReactElement
    return checkIfIsOnlyIcon(child.props.children, false)
  }
  const childElements = React.Children.toArray(children)
  const child = childElements[0]
  return childElements.length === 1 && React.isValidElement(child) && child.type == Icon
}


