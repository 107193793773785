import * as React from "react"
import * as AccordionPrimitive from "@radix-ui/react-accordion"

import { cn } from '../../lib/cn'
import { Icon } from "../../atoms/icon"

const Root = AccordionPrimitive.Root

const Item = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={cn("border border-gray-30 rounded-lg bg-white dark:border-gray-90 dark:bg-gray-100", className)}
    {...props}
  />
))
Item.displayName = "AccordionItem"

interface TriggerProps {
  actions?: React.ReactNode
}

const Trigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger & TriggerProps>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger> & TriggerProps
>(({ className, children, actions, ...props }, ref) => {

  const triggerRef = React.useRef<HTMLButtonElement | null>(null)

  React.useImperativeHandle(ref, () => triggerRef.current!)

  const scrollIntoViewIfOpen = () => {
    const triggerElement = triggerRef.current
    if (triggerElement && triggerElement.getAttribute('data-state') === 'open') {
      setTimeout(() => {
        const rect = triggerElement.getBoundingClientRect()
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop
        const offsetPosition = rect.top + scrollTop - 160
        window.scrollTo({
          top: offsetPosition,
          behavior: 'smooth'
        })
      }, 300)
    }
  }

  React.useEffect(() => {
    scrollIntoViewIfOpen()

    const triggerElement = triggerRef.current
    if (triggerElement) {
      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          if (mutation.type === 'attributes' && mutation.attributeName === 'data-state') {
            scrollIntoViewIfOpen()
          }
        })
      })

      observer.observe(triggerElement, { attributes: true })

      return () => {
        observer.disconnect()
      }
    }
  }, [])

  return (
    <AccordionPrimitive.Header className="relative p-4 flex gap-4 items-center hover:bg-gray-5 dark:hover:bg-gray-98">
      <AccordionPrimitive.Trigger
        ref={triggerRef}
        className={cn("flex gap-2 flex-1 items-center text-left justify-between font-medium transition-all grow", className)}
        {...props}
      >
        {children}
      </AccordionPrimitive.Trigger>
      {actions}
      <AccordionPrimitive.Trigger
        className="[&[data-state=open]>#chevron-icon]:rotate-180"
        {...props}
      >
        <Icon id="chevron-icon" name="chevron-down" size="md" className="shrink-0 transition-transform duration-200" />
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  )
})

Trigger.displayName = AccordionPrimitive.Trigger.displayName

const Content = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="overflow-hidden p-4 pt-0 text-sm transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
    {...props}
  >
    <div className={cn("pb-4 pt-0", className)}>{children}</div>
  </AccordionPrimitive.Content>
))

Content.displayName = AccordionPrimitive.Content.displayName

export const unstable_Accordion = Object.assign(Root, {
  Item, Trigger, Content,
})
